import React, { Component } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { getRef } from "../helpers/utils";

import Contain from "./contain";
import Row from "./row";
import Column from "./column";
import Register from "./register";

const CtaSection = styled.section`
  padding: 0 0 5rem;
`;

const CtaContain = styled.div`
  padding: 2rem;
  background: #dbf4ea;
  border-radius: 1rem;

  @media (min-width: 64rem) {
    padding: 4rem;
    .Column:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Heading = styled.h2`
  margin 0 0 1rem;
  @media (min-width: 64rem) {
    span {
      display: block;
    }
  }
`;

const Excerpt = styled.p`
  @media (max-width: 64rem) {
    padding: 0 0 2rem 0;
  }

  @media (min-width: 64rem) {
    font-size: 1.5rem;
    line-height: 1.6;
  }
`;

const Tagline = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #3ea962;
  margin: 0 0 1rem;
`;

const ActionsContain = styled.div`
  text-align: center;
  display: inline-block;

  @media (max-width: 64rem) {
    display: block;
  }
`;

const Button = styled.a`
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  background: #ff0283;
  color: #fff;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  display: block;

  &:hover {
    background: #dc0074;
    color: #fff;
  }

  @media (min-width: 64rem) {
    font-size: 1.4rem;
    padding: 1rem 1.4rem;
  }
`;

const ActionsContainDescription = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #ff0283;
  margin-top: 0.5rem;
`;

export default class Cta extends Component {
  state = {
    registerUrl: "https://quickpageapp.com/register",
  };

  componentDidMount() {
    const ref =
      getRef(this.props.location) ||
      queryString.parse(this.props.location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?ref=${ref}`,
      });
    }
  }

  render() {
    return (
      <CtaSection>
        <Contain>
          <CtaContain>
            <Row>
              <Column>
                <Heading>The time is NOW!</Heading>
                <Tagline>Quickpage is the ultimate pattern interrupt</Tagline>
                <Excerpt>
                  Virtual sales strategies are increasing and evolving rapidly
                  as sales people quickly learn how to do it. <br />
                  Now is not the time to be left behind.
                </Excerpt>
              </Column>

              <Column>
                <Register
                  location={this.props.location}
                  gaTrackLabel="Footer"
                />
              </Column>
            </Row>
          </CtaContain>
        </Contain>
      </CtaSection>
    );
  }
}
