import React from "react";
import Helmet from "react-helmet";

import favicon from "../../images/favicon.png";

export default ({
  title,
  description,
  keywords,
  url,
  img = "https://quickpage-assets.s3.amazonaws.com/marketing/facebook/facebook-kintz.jpg",
}) => {
  return (
    <Helmet
      title={title}
      meta={[
        { name: "description", content: description },
        { name: "keywords", content: keywords },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { property: "og:type", content: "website" },
        { property: "og:url", content: url },
        { property: "og:image", content: img },
        { property: "og:image:secure_url", content: img },
        { property: "og:image:width", content: "1200" },
        { property: "og:image:height", content: "630" },
      ]}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: favicon,
        },
      ]}
    />
  );
};
