import React from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import queryString from "query-string";
import { toast } from "react-toastify";
import Portal from "./portal";

export const Form = styled.form`
  padding: 1rem;

  label {
    display: block;
    margin: 0 0 0.5rem;
    border-radius: 3px;

    &.error {
      color: #ff0283;
      font-size: 0.8rem;
      text-align: center;
    }

    input {
      background: #ffffff;
      border: 1px solid #ddd;
      border-radius: 0.2rem;
      font-size: 1rem;
      width: 100%;
      padding: 0.875rem;
      outline: none;
      color: #181818;

      &:focus {
        background: #faf5da;
      }
    }

    select {
      width: 100%;
      height: 3rem;
      font-size: 1rem;
      border: 1px solid #ddd;
      background: #fff;
      outline: none;
      color: #181818;
      padding: 0 1rem;

      &:focus {
        background: #faf5da;
      }
    }
  }

  .PhoneInput {
    margin: 0 0 0.5rem;
    input {
      background: #ffffff;
      border: 1px solid #ddd;
      border-radius: 0.2rem;
      font-size: 1rem;
      width: 100%;
      padding: 0.875rem;
      outline: none;
      color: #181818;

      &:focus {
        background: #faf5da;
      }
    }
  }
`;

export const Button = styled.button`
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  padding: 1rem 1.5rem;
  letter-spacing: 0.5px;
  background: #ff0283;
  border-radius: 0.2rem;
  border: 0;
  transition: box-shadow 0.2s ease, border 0.2s ease;
  color: #fff;
  outline: none;
  margin: 1rem 0 0;

  &:hover {
    color: #fff;
    background: #dc0074;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  }
`;

export default class ModalPricingRequest extends React.Component {
  state = {
    disabled: false,
    phone: null,
  };

  componentDidMount() {
    const component = this;
    import("jquery").then(($) => {
      import("jquery-validation").then((validate) => {
        if ($.default) {
          $.default(component.form).validate();
        } else {
          $(component.form).validate();
        }

        component.setState({ disabled: false });
      });
    });

    const s = queryString.parse(window.location.search).s;

    if (s) {
      toast.success(
        "Expect a quick TEXT/Call from us with pricing details within the hour!"
      );
    }
  }

  onChangePhone = (phone) => {
    setTimeout(() => {
      this.setState({ phone });
    });
  };

  render() {
    const { disabled, phone } = this.state;

    return (
      <Portal
        title="Request team pricing"
        description="Enter your info for team pricing"
        onClicked={this.props.onClose}
      >
        <Form
          method="POST"
          ref={(form) => (this.form = form)}
          action="https://quickpage.activehosted.com/proc.php"
          noValidate=""
        >
          <input type="hidden" name="u" value="29" />
          <input type="hidden" name="f" value="29" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <label>
            <input
              type="text"
              name="fullname"
              ref={(i) => (this.nameInput = i)}
              placeholder="Full Name"
              required
            />
          </label>

          <label>
            <input
              type="text"
              name="email"
              ref={(i) => (this.emailInput = i)}
              placeholder="Email"
              required
            />
          </label>

          <PhoneInput
            placeholder="Phone"
            name="phone"
            value={phone}
            type="text"
            defaultCountry="US"
            countries={["US", "AU", "CA"]}
            onChange={this.onChangePhone}
            required={true}
          />

          <label style={{ display: "none" }}>
            <select
              name="field[6]"
              ref={(i) => (this.industrySelect = i)}
              required
            >
              <option value="Automotive" selected>
                Automotive
              </option>
            </select>
          </label>

          <label>
            <input
              type="text"
              name="field[2]"
              placeholder="Company name"
              ref={(i) => (this.teamNameInput = i)}
            />
          </label>

          <label>
            <select
              name="field[7]"
              ref={(i) => (this.teamSizeSelect = i)}
              required
            >
              <option value="" disabled selected>
                Select your Team Size
              </option>
              <option value="2-5">2-5</option>
              <option value="6-10">6-10</option>
              <option value="11-20">11-20</option>
              <option value="21+">21+</option>
            </select>
          </label>

          <Button type="submit" disabled={disabled}>
            Get Team PRICING NOW
          </Button>
        </Form>
      </Portal>
    );
  }
}
