import React, { Component } from "react";
import styled from "styled-components";
import queryString from "query-string";
import ReactGA from "react-ga";
import { getRef } from "../helpers/utils";

import ModalPricingRequest from "./modal-pricing";

const Button = styled.button`
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0.8rem;
  background: #ff0283;
  color: #fff;
  border-radius: 0.2rem;
  transition: all 0.2s;
  letter-spacing: 0.1rem;
  display: block;
  border: 0;
  outline: none;
  cursor: pointer;
  height: 4rem;

  &:hover {
    background: #dc0074;
    color: #fff;
  }
`;

export default class Register extends Component {
  state = {
    visible: false,
  };

  video;

  componentDidMount() {
    const ref =
      getRef(this.props.location) ||
      queryString.parse(this.props.location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?ref=${ref}`,
      });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ disable: true });
    const { registerUrl, email } = this.state;
    const data = new FormData(event.target);

    fetch("https://quickpage.activehosted.com/proc.php", {
      method: "POST",
      body: data,
      mode: "no-cors",
    })
      .then((response) => {
        if (registerUrl.includes("ref")) {
          window.location.href = `${registerUrl}&fse=${email}`;
        } else {
          window.location.href = `https://quickpageapp.com/register?fse=${email}`;
        }

        this.setState({ disable: false });
        ReactGA.event({
          category: "Register",
          action: "Register form",
          label: this.props.gaTrackLabel,
        });
      })
      .catch((error) => {
        this.setState({ disable: false });
        console.log("Request failed", error);
      });
  };

  render() {
    const { visible } = this.state;
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.setState({ visible: !visible })}
        >
          Request pricing
        </Button>
        {visible && (
          <ModalPricingRequest
            onClose={() => this.setState({ visible: !visible })}
          />
        )}
      </div>
    );
  }
}
